"use client";

import DropdownWithAuthLangSelect from "./DropdownWithAuthLangSelect";
import { cn } from "@hits/hits-ui/utils/classname";
import LogoIcon from "@shared/ui/icons/LogoIcon";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface AuthPageGNBProps {
  locale: string;
}
export default function AuthPageGNB({ locale }: AuthPageGNBProps) {
  const params = usePathname();
  const isLabspace = params.includes("labspace");
  const isSecurityCodeRegister = params.includes("security-code-register");
  return (
    <header
      className={cn(
        "bg-[#fdfeff]",
        !isSecurityCodeRegister && "fixed left-0 right-0 z-20",
        isLabspace && "lgtb:bg-transparent",
      )}
    >
      <div className="width-before-scroll-bar smmb:px-5 flex h-16 flex-shrink-0 items-center justify-between px-10">
        <Link
          className="relative"
          data-cy="AuthPageGNB-link"
          href={`/${locale}`}
        >
          <LogoIcon className="fill-c-gray/900 h-6" />
          {/* TODO: stage에서도 보이는 원인 찾기 */}
          {/* {process.env.APP_ENV !== "production" && (
            <div className="absolute text-xl font-bold text-blue-600 -right-10 top-1">
              Dev
            </div>
          )} */}
        </Link>

        <DropdownWithAuthLangSelect locale={locale} sideOffset={20} />
      </div>
    </header>
  );
}
