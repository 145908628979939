import { SVGProps } from "react";

export default function LogoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 125 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.376 8.56h-5V.227H.043v20h3.333v-8.334h5v8.334h3.334v-20H8.376V8.56ZM20.876 7.293 18.326.227h-3.283v.275l2.95 8.058 1.217 3.333v8.334h3.333v-8.334l1.192-3.333L26.71.227h-3.267l-2.567 7.066ZM38.839.227h-9.167v20h3.333v-8.334h5.834c1.383 0 2.5-1.116 2.5-2.5V2.727c0-1.384-1.117-2.5-2.5-2.5Zm-.834 4.858V8.56h-5v-5h5v1.525ZM44.672 8.555v9.166c0 1.384 1.117 2.5 2.5 2.5h9.167v-3.333h-8.334v-5h8.334V8.555H44.672ZM84.49 16.893V.227h-3.334v17.5c0 1.383 1.117 2.5 2.5 2.5h9.167v-3.334H84.49ZM107.177 8.56l-.834-5-.15-.883c0-.25-.041-.492-.116-.717a2.497 2.497 0 0 0-2.375-1.733h-2.475a2.497 2.497 0 0 0-2.492 2.35l-.166.983-.834 5-.558 3.333-1.392 8.334h3.334l1.391-8.334.558-3.333.559-3.367.141-.841v-.034l.125-.758h1.109l.133.775.142.858.558 3.367.558 3.333 1.392 8.334h3.333l-1.391-8.334-.559-3.333h.009ZM121.62.227h-9.167v20h9.167c1.383 0 2.5-1.117 2.5-2.5v-5a2.504 2.504 0 0 0-2.5-2.5 2.504 2.504 0 0 0 2.5-2.5v-5c0-1.384-1.117-2.5-2.5-2.5Zm-.834 14.858v1.808h-5v-5h5v3.192Zm0-10V8.56h-5v-5h5v1.525ZM69.58.223h-.833l.025.134h-.025V.215h-8.333v20.008h3.333V3.557h5v5h-1.941l.558 3.333 1.392 8.333h3.333l-1.392-8.333-.041-.242A2.496 2.496 0 0 0 72.08 9.39V2.723c0-1.383-1.117-2.5-2.5-2.5ZM44.672.227V3.56h11.667V.227H44.672Z"
        fill="#111827"
      />
    </svg>
  );
}
