"use client";

import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
} from "@hits/hits-ui/dropdown/round.dropdown";
import { cn } from "@hits/hits-ui/utils/classname";
import LanguageSwitchButton from "@shared/ui/language/language-switch-button";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { MdOutlineExpandLess, MdOutlineLanguage } from "react-icons/md";

export default function DropdownWithAuthLangSelect({
  locale,
  className,
}: {
  locale: string;
  className?: string;
  sideOffset?: number;
}) {
  const t = useTranslations("AUTH.LangDropdown");
  const [isHover, setIsHover] = useState(false);

  return (
    <DropdownRoot modal={false}>
      <div
        className="flex items-center "
        onMouseLeave={() => setIsHover(false)}
        onMouseOver={() => setIsHover(true)}
      >
        <DropdownTrigger
          aria-label="lang-trigger"
          className={cn(
            "text-gray-c-800 hover:bg-blue-000 h-[38px] rounded-md p-2 focus:outline-none",
            className,
          )}
          data-cy="DropdownWithAuthLangSelect-trigger"
          id="lang-trigger"
        >
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
              <MdOutlineLanguage className="text-blue-600" size={18} />
              <span className="body1-medium text-[16px]">
                {t(locale as "ko" | "en")}
              </span>
            </div>
            <MdOutlineExpandLess className="rotate-180" size={18} />
          </div>
        </DropdownTrigger>
        <DropdownContent
          align="center"
          className="text-gray-c-700 p-1.5 text-sm"
          forceMount={isHover || undefined}
        >
          <DropdownItem
            asChild
            className={clsx(
              locale === "ko" &&
                "cursor-auto bg-blue-100 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-100 ",
              "w-full",
              "flex justify-center",
            )}
            data-cy="DropdownWithAuthLangSelect-ko"
          >
            <LanguageSwitchButton locale="ko">
              {t("selectKO")}
            </LanguageSwitchButton>
          </DropdownItem>
          <DropdownItem
            asChild
            className={clsx(
              locale === "en" &&
                "cursor-auto bg-blue-100 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-100",
              "w-full",
              "flex justify-center",
            )}
            data-cy="DropdownWithAuthLangSelect-en"
          >
            <LanguageSwitchButton locale="en">
              {t("selectEN")}
            </LanguageSwitchButton>
          </DropdownItem>
        </DropdownContent>
      </div>
    </DropdownRoot>
  );
}
