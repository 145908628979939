"use client";

import { cn } from "../utils/classname";
import type {
  DropdownMenuArrowProps,
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuSeparatorProps,
  DropdownMenuTriggerProps,
} from "./dropdown";
import { DropdownMenu } from "./dropdown";
import { forwardRef } from "react";

export const DropdownRoot = DropdownMenu.Root;
export const DropdownTrigger = forwardRef<
  HTMLButtonElement,
  DropdownMenuTriggerProps
>(({ children, ...params }, ref) => {
  return (
    <DropdownMenu.Trigger {...params} ref={ref}>
      {children}
    </DropdownMenu.Trigger>
  );
});
DropdownTrigger.displayName = "DropdownTrigger";

export const DropdownContent = forwardRef<
  HTMLDivElement,
  DropdownMenuContentProps
>(({ className, ...props }, ref) => {
  return (
    <DropdownMenu.Portal forceMount={props.forceMount}>
      <DropdownMenu.Content
        className={cn(
          "flex flex-col gap-1",
          // TODO 드롭다운에 사용되는 쉐도우가 shadow-gray02dp, shadow-dropdown 두가지가 있음. 어떤 경우에 선택되는지 확인 후에 각 상태에 맞게 적용 할 수 있도록 컴포넌트 수정
          "shadow-gray02dp z-50 overflow-hidden rounded-lg bg-white",
          "text-sm",
          "px-1.5 py-1",
          className,
        )}
        ref={ref}
        {...props}
      />
    </DropdownMenu.Portal>
  );
});
DropdownContent.displayName = "DropdownContent";

export const DropdownItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <DropdownMenu.Item
        {...props}
        className={cn(
          "active:bg-blue-000 hover:bg-gray-bg-c-200 focus:bg-gray-bg-c-200 cursor-pointer outline-none",
          "flex items-center gap-4 rounded px-2.5 py-2",
          className,
        )}
        ref={ref}
      />
    );
  },
);
DropdownItem.displayName = "DropdownItem";

export const DropdownSeparator = forwardRef<
  HTMLDivElement,
  DropdownMenuSeparatorProps
>((props, ref) => {
  return (
    <DropdownMenu.Separator
      {...props}
      className={cn("h-px bg-black", props.className)}
      ref={ref}
    />
  );
});
DropdownSeparator.displayName = "DropdownSeparator";

export const DropdonwArrow = forwardRef<SVGSVGElement, DropdownMenuArrowProps>(
  (props, ref) => {
    return (
      <DropdownMenu.Arrow
        {...props}
        className={cn("h-2 w-4", props.className)}
        ref={ref}
      />
    );
  },
);
DropdonwArrow.displayName = "DropdonwArrow";

const RoundDropdown = {
  Root: DropdownRoot,
  Trigger: DropdownTrigger,
  Content: DropdownContent,
  Item: DropdownItem,
  Separator: DropdownSeparator,
  Arrow: DropdownMenu.Item,
  CheckboxItem: DropdownMenu.CheckboxItem,
  ItemIndicator: DropdownMenu.ItemIndicator,
  Portal: DropdownMenu.Portal,
};

export default RoundDropdown;
