"use client";

import { usePathname, useSearchParams } from "next/navigation";
import React, { ComponentProps, forwardRef, useTransition } from "react";

const redirectLocale = (
  pathName: string,
  locale: string,
  searchParams?: string,
) => {
  const segments = pathName.split("/");
  const IS_CURRENT_KO = segments[1] !== "en";

  let newPathname = segments.join("/");
  if (IS_CURRENT_KO) {
    segments[0] = locale;
    newPathname = ["", ...segments].join("/");
  } else {
    segments[1] = locale;
    newPathname = segments.join("/");
  }

  if (searchParams) {
    return `${newPathname}?${searchParams}`;
  }
  return newPathname;
};

const LanguageSwitchButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button"> & { locale: string }
>(({ locale, ...props }, ref) => {
  // 언어 변경 시 채널톡 사라짐 이슈 해결을 위해 window.location 사용
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const [isPending, startTransition] = useTransition();

  const handleClick = () => {
    startTransition(() => {
      window.location.replace(
        redirectLocale(pathName, locale, searchParams.toString()),
      );
    });
  };

  return (
    <button {...props} disabled={isPending} ref={ref} onClick={handleClick} />
  );
});
LanguageSwitchButton.displayName = "LanguageSwitchButton";

export default LanguageSwitchButton;
